import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  MenuItem,
  Box,
  Container,
  ListItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { NavLink } from "react-router-dom";
import { CgClose } from "react-icons/cg";
import { AuthContext } from "src/context/Auth";
const headersData = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Features",
    href: "/features",
  },
  {
    label: "Product",
    href: "/product",
  },
  {
    label: "Contact",
    href: "/contact",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#262626",
    padding: "0px 20px",
    textDecoration: " none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#EC1F24",
    },
    "&:hover": {
      color: "#EC1F24",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    padding: " 5px 0px",
  },

  logoDrawer: {
    width: "140px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 0px",
    height: "100%",
    background: "#ffffff",
    color: "#262626",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "baseline",
    width: "280px",
    margin: "0 auto",
    "@media (max-width: 599px)": {
      alignItems: "center",
    },
  },
  drawericon: {
    color: "#000",
    marginLeft: "0px !important",
    fontSize: "25px",
  },
  drawericonCLose: {
    position: "absolute",
    top: "7px",
    right: "20px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
      width: "75px",
    },
  },
  menuMobile: {
    fontSize: "15px",
    fontWeight: "500",
    paddingLeft: "10px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
      fontSize: "15px",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },

  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },

  menuButton1: {
    paddingLeft: "0",
  },
}));

export default function Header() {
  const auth = useContext(AuthContext);
  const { userLoggedIn } = auth;
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    drawericonCLose,
    logoDrawer,
    mainHeader,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {getMenuButtons()}
          {loginButton}
          &nbsp;
        </Box>
      </Toolbar>
    );
  };
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          className="mobileDrawer"
        >
          <IconButton
            className={drawericonCLose}
            {...{
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerClose,
            }}
          >
            <CgClose
              width="60px"
              height="60px"
              style={{ color: "#EC1F24", fontSize: "26px" }}
            />
          </IconButton>
          <div className={drawerContainer}>
            <Box mb={1}>
              <img className={logoDrawer} src="images/logo.png" alt="" />
            </Box>

            {getDrawerChoices()}
            {loginButton}
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#EC1F24", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };
  //mobile end
  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          {href === "/contact" ? (
            <>
              <Button
                onClick={() => {
                  if (href === "/contact") {
                    window.open("mailto:support@gmail.com");
                  }
                }}
                {...{
                  key: label,
                  color: "inherit",
                  to: href !== "/contact",
                  component: Link,
                  className: menuButton1,
                }}
              >
                <MenuItem className={menuMobile}>{label}</MenuItem>
              </Button>
            </>
          ) : (
            <Button
              {...{
                key: label,
                color: "inherit",
                to: href,
                component: Link,
                className: menuButton1,
              }}
            >
              <MenuItem className={menuMobile}>{label}</MenuItem>
            </Button>
          )}
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  const loginButton = (
    <>
      {!userLoggedIn && (
        <Button
          variant="contained"
          className="btn-15 whitespacePre"
          to="/login"
          color="secondary"
          component={Link}
          fullWidth
        >
          Login
        </Button>
      )}

      <Button
        variant="contained"
        className="btn-15 whitespacePre"
        color="primary"
        fullWidth
        onClick={() => {
          if (!userLoggedIn) {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("number");
            window.localStorage.removeItem("email");
            auth.setUserData({});
          }

          history.push(!userLoggedIn ? "/sign-up" : "/dashboard");
        }}
      >
        {!userLoggedIn ? "Sign Up" : "Dashboard"}
      </Button>
    </>
  );

  const getMenuButtons = (activeClassName) => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          {href === "/contact" ? (
            <>
              <ListItem>
                <Link
                  onClick={() => {
                    if (href === "/contact") {
                      window.open("mailto:support@gmail.com");
                    }
                  }}
                  className={menuButton}
                  activeClassName="active"
                >
                  {label}
                </Link>
              </ListItem>
            </>
          ) : (
            <NavLink
              exact
              {...{
                key: label,
                color: "inherit",
                to: href,
                className: menuButton,
                activeClassName: "active",
              }}
            >
              {" "}
              {label}
            </NavLink>
          )}
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ffffff", border: "none" }}
      >
        <Container maxWidth="lg">
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
