import React from "react";
import {
  Typography,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  Slide,
} from "@material-ui/core";
import { AiOutlineLogout } from "react-icons/ai";
export default function LogoutModal({ close, onClickFun, open }) {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
    >
      <DialogContent>
        <Box align="center">
          <AiOutlineLogout
            style={{
              marginRight: "16px",
              color: "#EC1F24",
              fontSize: "65px",
            }}
          />
          <Typography
            variant="h5"
            style={{
              color: "#222222",
              fontFamily: "poppins",
              fontWeight: "400",
              maxWidth: "275px",
            }}
          >
            Are you sure, you want to logout?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Box mt={2} mb={2}>
          <Button
            className="modelbtn"
            color="secondary"
            variant="contained"
            style={{ padding: "8px 21px" }}
            onClick={close}
          >
            No
          </Button>
          &nbsp; &nbsp;
          <Button
            className="modelbtnyes"
            style={{
              padding: "8px 21px",
            }}
            color="primary"
            variant="contained"
            onClick={onClickFun}
          >
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
