import React from "react";
import { Redirect } from "react-router-dom";

export default function AuthGuard(props) {
  const { children } = props;

  if (!window.localStorage.getItem("token")) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}
