const baseFontSize = 16; // Set your base font size in pixels
const reductionFactor = 0.9; // Adjust the reduction factor as desired

const fontSizeRem = (size) => {
  return `${(size * reductionFactor) / baseFontSize}rem`;
};

export default {
  h1: {
    fontWeight: 500,
    fontSize: fontSizeRem(45),
    lineHeight: fontSizeRem(52),
    fontFamily: "Clash Display",
    "@media(max-width:767px)": {
      fontSize: fontSizeRem(30) + " !important",
      lineHeight: fontSizeRem(40),
    },
  },
  h2: {
    fontWeight: 500,
    whiteSpace: "pre",
    fontSize: fontSizeRem(40),
    lineHeight: fontSizeRem(45),
    fontFamily: "Clash Display",
    wordBreak: "break-word",
    "@media(max-width:767px)": {
      fontSize: fontSizeRem(25) + " !important",
      lineHeight: fontSizeRem(30) + " !important",
    },
  },
  h3: {
    fontWeight: 600,
    fontSize: fontSizeRem(28),
    lineHeight: fontSizeRem(30),
    fontFamily: "Clash Display",
    "@media(max-width:767px)": {
      fontSize: fontSizeRem(18) + " !important",
    },
  },
  h4: {
    fontWeight: 600,
    fontSize: fontSizeRem(25),
    lineHeight: fontSizeRem(30),
    fontFamily: "Clash Display",
    "@media(max-width:767px)": {
      fontSize: fontSizeRem(18) + " !important",
    },
  },
  h5: {
    fontWeight: 500,
    fontSize: fontSizeRem(20),
    lineHeight: fontSizeRem(30),
    fontFamily: "Clash Display",
    "@media(max-width:767px)": {
      fontSize: fontSizeRem(16) + " !important",
    },
  },
  h6: {
    fontWeight: 500,
    fontSize: fontSizeRem(18),
    lineHeight: fontSizeRem(24),
    color: "#262626",
    fontFamily: "'Poppins', sans-serif",
    "@media(max-width:767px)": {
      lineHeight: fontSizeRem(22),
      fontSize: fontSizeRem(14) + " !important",
    },
  },
  overline: {
    fontWeight: 500,
    fontFamily: "'Poppins', sans-serif",
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "Clash Display",
  },
  body2: {
    fontSize: fontSizeRem(14),
    fontWeight: "400",
    lineHeight: fontSizeRem(20),
    fontFamily: "'Poppins', sans-serif",
    "@media(max-width:767px)": {
      fontSize: fontSizeRem(12),
      lineHeight: fontSizeRem(18),
    },
  },
  body1: {
    fontWeight: 400,
    lineHeight: fontSizeRem(20),
    fontSize: fontSizeRem(12),
    // fontFamily: "'Poppins, sans-serif'",
  },
};
