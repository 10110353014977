import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { calculateTimeLeft } from "src/utils";
import { getAPIHandler } from "src/apiConfig/service";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [coinList, setCoinList] = useState([]);
  const [tabs, setTabs] = useState("Crypto");
  const [ip, setIP] = useState("");
  const [livePrice, setLivePrice] = useState("");
  const [dashboardData, setDashboardData] = useState({});
  const [allLivePrice, setAllLivePrice] = useState({});

  //function to load the user profile data to pass in to entire enviornment
  const getProfileDataHandler = async (token) => {
    try {
      const response = await getAPIHandler("profile", token);
      if (response.data.status == 200) {
        const { currency } = response.data.data;
        setUserData(response.data.data);
        currencyConverterAPI(token, currency);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  //function to ftch the current price of DT and EUR
  const currencyConverterAPI = async (token, currency) => {
    try {
      const response = await getAPIHandler("livePrice", token);

      if (response.data.status === 200) {
        const { eurPrice, tndPrice } = response.data.data;
        setLivePrice(
          currency === "EUR" ? eurPrice : currency === "DT" ? tndPrice : 0
        );
        setAllLivePrice({
          EUR: eurPrice,
          DT: tndPrice,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function to get all the coinlist used in the platform with network listing
  const getCoinListHandler = async (token) => {
    try {
      const response = await getAPIHandler("coinList", token);
      if (response.data.status === 200) {
        const filterCoin = response?.data?.data?.filter(
          (item) => item.coinType !== "Fiat"
        );
        setCoinList(filterCoin);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //function to get user dashboard details for both fiat and crypto

  const getUserDashboardDataHandler = async (token) => {
    try {
      const response = await getAPIHandler("userDashboard", token);
      if (response.data.status === 200) {
        setDashboardData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //context data object that has been passed to the data layer of the application

  let data = {
    ip,
    tabs,
    userLoggedIn: isLogin,
    userData,
    timeLeft,
    coinList,
    livePrice,
    dashboardData,
    allLivePrice,
    setTabs: (data) => setTabs(data),
    setEndtime: (data) => setEndtime(data),
    setUserData: (data) => setUserData(data),
    getProfileDataHandler: (data) => getProfileDataHandler(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    getData();
    getCoinListHandler();
    if (window.localStorage.getItem("token")) {
      getProfileDataHandler(window.localStorage.getItem("token"));

      getUserDashboardDataHandler(window.localStorage.getItem("token"));
    }
  }, [window.localStorage.getItem("token")]);

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
