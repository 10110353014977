import React from "react";
import { makeStyles, Box, Hidden } from "@material-ui/core";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  loginLayoutBox: {
    background: "#fff",
    zIndex: "9",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    "& .backBtn": {
      position: "absolute",
      top: 30,
      left: 380,
      border: "1px solid rgba(0, 0, 0, 0.08)",
      [theme.breakpoints.down("md")]: {
        top: 15,
        left: 15,
      },
    },
    "& .loginLayoutBoxImg": {
      width: "347px",
      zIndex: "1",
      overflow: "hidden",
      position: "fixed",
      backgroundSize: "cover",
      backgroundColor: "#000",
      backgroundImage: "url(images/loginback.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: "100vh",
      left: 0,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      "& h1": {
        fontSize: "65px",
      },
    },
  },
  loginContentLayoutBox: {
    background: theme.palette.background.white,
    position: "relative",
    paddingLeft: "350px",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingLeft: "0px",
    },
  },
  imgBox: {
    width: "200px",
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
  },
}));

export default function LoginLayout({ children }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.loginLayoutBox}>
        <Box className="loginLayoutBoxImg">
          <Box
            align="center"
            onClick={() => history.push("/")}
            style={{ cursor: "pointer" }}
          >
            <img src="images/loginlogo.png" alt="" />
          </Box>
        </Box>
        <Box className={classes.loginContentLayoutBox}>
          <Hidden lgUp>
            <Box
              align="center"
              mt={2}
              className="loginLogo11"
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            >
              <img src="images/logo.png" alt="abc" className={classes.imgBox} />
            </Box>
          </Hidden>

          {children}
        </Box>
      </Box>
    </>
  );
}
LoginLayout.propTypes = {
  children: PropTypes.node,
};
