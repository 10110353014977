import { useEffect, useRef } from "react";

export const SessionManager = ({ timeout, onIdle }) => {
  const timerRef = useRef(null);

  useEffect(() => {
    const resetTimer = () => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(onIdle, timeout);
    };

    const handleEvent = () => {
      resetTimer();
    };

    window.addEventListener("mousemove", handleEvent);
    window.addEventListener("keydown", handleEvent);

    resetTimer();

    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener("mousemove", handleEvent);
      window.removeEventListener("keydown", handleEvent);
    };
  }, [timeout, onIdle]);

  return null;
};
