import React, { useContext, useMemo, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  makeStyles,
  IconButton,
  Typography,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Grid,
  Box,
  Popper,
  Fade,
  Paper,
  Divider,
  ClickAwayListener,
} from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { AuthContext } from 'src/context/Auth'
import Logo from 'src/component/Logo'
import { BiBell, BiMenu } from 'react-icons/bi'
import { FaRegUser } from 'react-icons/fa'
import { IoShieldCheckmarkOutline } from 'react-icons/io5'
import { HiOutlineKey } from 'react-icons/hi'
import { IoIosHelpCircleOutline, IoMdChatbubbles } from 'react-icons/io'
import { AiOutlineLogout } from 'react-icons/ai'
import ProfileAvatar from 'src/component/ProfileAvatar'
import LogoutModal from 'src/component/LogoutModal'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: '7px 30px 7px 30px',

    background: theme.palette.background.header,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 0px 20px',
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  mainheader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& svg': {
      color: theme.palette.text.primary,
    },
    '& .leftBox': {
      width: '306px',
      [theme.breakpoints.down('md')]: {
        width: '200px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '150px',
      },
      '& img': {
        width: '150px',
        [theme.breakpoints.down('xs')]: {
          width: '104px',
          paddingLeft: '0 !important',
        },
      },
    },
  },
  mainpopperBox: {
    '& .MuiPaper-root': {
      // boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.25) !important",
      background: '#ffffff',
      borderRadius: '15px',
      marginTop: '47px',
      maxWidth: '300px',
      width: '100%',
      border: '1px solid #80808021',
      position: 'relative',
      zIndex: '9999',
    },
    '& h6': {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      letterSpacing: '0em',
      textAlign: 'center',
    },
    '& svg': {
      fontSize: '20px',
      marginRight: '10px',
      color: '#9D9D9D',
    },
  },
  mainLeafBox: {
    padding: '15px 14px',
    cursor: 'pointer',
    '&:hover': {
      background: '#f5f5f5',
    },
  },
}))

const sidebarData = [
  {
    icon: <FaRegUser />,
    list: 'My account',
    route: '/my-account',
  },
  {
    icon: <HiOutlineKey />,
    list: 'Password & Security',
    route: '/security',
  },
  {
    icon: <IoShieldCheckmarkOutline />,
    list: 'Privacy Policy',
    route: '/privacy',
  },
  {
    icon: <IoIosHelpCircleOutline />,
    list: 'Terms & Condition',
    route: '/terms-and-conditions',
  },
  {
    icon: <IoMdChatbubbles />,
    list: 'Rules & FAQ’s',
    route: '/faqs',
  },
]

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()
  const auth = useContext(AuthContext)
  const { userData, setUserData, userLogIn } = auth
  const history = useHistory()
  const [isLogout, setIsLogout] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleClickAway = () => {
    setOpen(false)
  }
  const [placement, setPlacement] = React.useState()
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }
  const popupData = useMemo(() => {
    if (!userData.kyc) {
      return [
        ...sidebarData,
        {
          icon: <FaRegUser />,
          list: 'KYC Verification',
          route: '/id-verify',
        },
      ]
    } else if (userData?.kyc?.kycStatus === 'REJECTED') {
      return [
        ...sidebarData,
        {
          icon: <FaRegUser />,
          list: 'Re-submit KYC',
          route: '/id-verify',
        },
      ]
    } else {
      return sidebarData
    }
  }, [userData])

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      style={{ boxShadow: '0px 4px 4px rgb(0 0 0 / 10%)' }}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="#00e0b0"
            onClick={onMobileNavOpen}
            style={{ padding: '0px' }}
          >
            <SvgIcon>
              <BiMenu style={{ color: '#EC1F24', fontSize: '25px' }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        &nbsp; &nbsp;
        <Box className={classes.mainheader}>
          <Grid container alignItems="center">
            <Grid item lg={3} md={3} sm={4} xs={6}>
              <Box className="leftBox">
                <Link to="/">
                  <Logo width="125" />
                </Link>
              </Box>
            </Grid>
            <Hidden xsDown>
              <Grid lg={4} md={6} sm={5}></Grid>
            </Hidden>
            <Grid lg={5} md={3} sm={3} xs={6}>
              <Box className="displayEnd">
                <Box className="themeButton"></Box>
                <IconButton
                  onClick={() => history.push('/notification')}
                  style={{
                    background: 'rgba(196, 196, 196, 0.4)',
                    padding: '10px',
                  }}
                >
                  <BiBell />
                </IconButton>
                <Box
                  ml={2}
                  onClick={handleClick('left-start')}
                  style={{ cursor: 'pointer' }}
                >
                  {/* <Avatar
                    style={{ width: "50px", height: "50px" }}
                    src="images/avatar2.png"
                  /> */}
                  <ProfileAvatar userData={userData} />
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={handleClickAway}
                  >
                    <Popper
                      style={{
                        zIndex: '999',
                      }}
                      className={classes.mainpopperBox}
                      open={open}
                      anchorEl={anchorEl}
                      placement={placement}
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                            <Box
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                background: '#f5f5f5',
                                padding: '10px 14px',
                                borderRadius: '10px',
                                marginBottom: '15px',
                              }}
                            >
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <ProfileAvatar userData={userData} />
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: '500',
                                    cursor: 'pointer',
                                    marginLeft: '10px',
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  {userData && userData.fullName}
                                </Typography>
                              </Box>
                              {/* <Box>
                                <Typography
                                  variant="body2"
                                  style={{
                                    color: "#EC1F24",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() => history.push("/my-account")}
                                >
                                  View own profile
                                </Typography>
                              </Box> */}
                            </Box>
                            {popupData &&
                              popupData.map((data) => (
                                <>
                                  <Box
                                    className={`${classes.mainLeafBox} displayStart`}
                                    alignItems="flex-start"
                                    onClick={() =>
                                      history.push(`${data.route}`)
                                    }
                                  >
                                    <Box>{data.icon}</Box>
                                    <Box>
                                      <Typography variant="h6">
                                        {data.list}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Divider
                                    style={{ background: 'rgba(0, 0, 0, 0.2)' }}
                                  />
                                </>
                              ))}
                            <Box
                              className="displayStart"
                              alignItems="flex-start"
                              style={{
                                padding: '15px 10px 0',
                                cursor: 'pointer',
                              }}
                              onClick={() => setIsLogout(true)}
                            >
                              <Box>
                                <AiOutlineLogout style={{ color: '#EC1F24' }} />
                              </Box>
                              <Box>
                                <Typography
                                  variant="h6"
                                  style={{ color: '#EC1F24' }}
                                >
                                  Logout
                                </Typography>
                              </Box>
                            </Box>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </ClickAwayListener>
                </Box>
                {isLogout && (
                  <LogoutModal
                    open={isLogout}
                    close={() => setIsLogout(false)}
                    onClickFun={() => {
                      window.localStorage.removeItem('token')
                      setUserData({})
                      userLogIn(false, null)
                      history.push('/')
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
}
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
}

export default TopBar
