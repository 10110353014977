import React from "react";
import { generateUniqueAvatar } from "src/utils";
import { Avatar } from "@material-ui/core";
export default function ProfileAvatar({ userData }) {
  function checkProfileImage() {
    if (
      userData &&
      userData.kyc &&
      userData.kyc.kycStatus &&
      userData.kyc.kycStatus === "ACCEPTED"
    ) {
      return (
        userData &&
        userData.kyc &&
        userData.kyc.document &&
        userData.kyc.document[0] &&
        userData.kyc.document[0].selfieUrl
      );
    } else {
      return generateUniqueAvatar(userData?.userId);
    }
  }

  return <Avatar alt="User" src={checkProfileImage()} />;
}
