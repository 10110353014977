import axios from "axios";
import apiConfigs from "../ApiConfig";
import toast from "react-hot-toast";
export const dataPostHandler = async (endPoint, dataToSend) => {
  try {
    return await axios({
      method: "POST",
      url: apiConfigs[endPoint],
      headers: {
        Authorization: window.localStorage.getItem("token"),
      },
      data: dataToSend,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
export const uploadFileHandler = async (endPoint, dataToSend) => {
  try {
    return await axios({
      method: "POST",
      url: apiConfigs[endPoint],
      headers: {
        Authorization: window.localStorage.getItem("token"),
      },
      data: [dataToSend],
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const putAPIHandler = async (endPoint, dataToSend) => {
  try {
    return await axios.put(apiConfigs[endPoint], dataToSend, {
      headers: {
        Authorization: window.localStorage.getItem("token"),
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
export const putAPIHandlerParams = async (endPoint, dataToSend) => {
  try {
    return await axios({
      method: "PUT",
      url: apiConfigs[endPoint],
      headers: {
        Authorization: window.localStorage.getItem("token"),
      },
      params: dataToSend,
    });
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.message);
  }
};

export const getAPIHandlerParams = async (endPoint, dataToSend, source) => {
  try {
    return await axios({
      method: "GET",
      url: apiConfigs[endPoint],
      headers: {
        Authorization: window.localStorage.getItem("token"),
      },
      cancelToken: source ? source.token : null,
      params: dataToSend,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAPIHandler = async (endPoint, token, source) => {
  try {
    return await axios({
      method: "GET",
      url: apiConfigs[endPoint],
      headers: {
        Authorization: token,
      },
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    if (error?.response?.data?.error === "Unauthorized") {
      window.localStorage.removeItem("token");
      toast.error("Your session has been expired! Please login to continue.");
      window.open("/login", "_self");
    }
  }
};

export const deleteAPIHandler = async (endPoint, dataToSend) => {
  try {
    return await axios({
      method: "DELETE",
      url: apiConfigs[endPoint],
      headers: {
        Authorization: window.localStorage.getItem("token"),
      },
      params: dataToSend,
    });
  } catch (error) {
    console.log(error);
  }
};
