import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/loader",
    layout: LoginLayout,
    component: lazy(() => import("src/component/ToastCheck")),
  },

  {
    exact: true,
    path: "/transaction",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/p2p/transactionHistory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Dash")),
  },

  {
    exact: true,
    path: "/sign-up",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/signup/Create")),
  },
  {
    exact: true,
    path: "/create-account",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/signup/CreateAccount")),
  },
  {
    exact: true,
    path: "/swap",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Swap/Swap")),
  },

  {
    exact: true,
    path: "/features",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Features")),
  },
  {
    exact: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Notification")),
  },
  {
    exact: true,
    path: "/product",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Download")),
  },
  {
    exact: true,
    guard: true,
    path: "/id-verify",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Kyc/Index")),
  },

  {
    exact: true,
    path: "/uplaod-selfie",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Kyc/UploadSelfie")),
  },
  {
    guard: true,
    exact: true,
    path: "/personal",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/signup/PersonalInfo")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/model",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/WalletModal")),
  },
  {
    exact: true,
    path: "/sidebar-dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/DashboardActivity")),
  },
  {
    guard: true,
    exact: true,
    path: "/my-account",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/MyAccount")),
  },
  {
    exact: true,
    path: "/security",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/PasswordAndSecurity/PasswordSecurity")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/sms-auth",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/PasswordAndSecurity/SmsAuth")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/email-auth",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/PasswordAndSecurity/EmailAuth")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/google-auth",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/PasswordAndSecurity/GoogleAuth")
    ),
  },
  {
    exact: true,
    path: "/google-qr",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/PasswordAndSecurity/GoogleAuthQr")
    ),
  },
  //wallet
  {
    exact: true,
    guard: true,
    path: "/my-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/Mywallet")),
  },
  {
    guard: true,
    exact: true,
    path: "/deposit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/Deposite")),
  },
  {
    exact: true,
    guard: true,
    path: "/withdraw",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/Widthdraw")),
  },
  {
    exact: true,
    path: "/confirm-withdraw",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Wallet/ConfirmWithdraw")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/withdraw-fiat",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Wallet/WithdrawFiat")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/transfer",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/Transfer")),
  },
  {
    guard: true,
    exact: true,
    path: "/dt-deposite",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Wallet/Dtdeposite")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/transaction-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Wallet/historytable")
    ),
  },
  {
    exact: true,
    path: "/skelaton",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/ListSkeleton")),
  },
  {
    exact: true,
    path: "/withdraw-by-bank",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Wallet/WithdrawByBank")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/bank-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Wallet/BankDetails")
    ),
  },
  //change layout
  {
    guard: true,
    exact: true,
    path: "/add-imps",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/PaymentMethod/AddImps")),
  },
  {
    guard: true,
    exact: true,
    path: "/add-bank",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/PaymentMethod/AddBank")),
  },
  {
    exact: true,
    path: "/select-pay-method",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/PaymentMethod/SelectPaymentMethod")
    ),
  },
  ///////p2p
  {
    guard: true,
    exact: true,
    path: "/p2p-exchange",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/p2p/p2pExchange/index")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/my-ads",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/p2p/p2pExchange/Myads")
    ),
  },
  ////////////order
  {
    exact: true,
    guard: true,
    path: "/order-created",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/p2p/p2pExchange/Order/OrderCreated")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/user-profile-ads",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserProfileAds/index")
    ),
  },
  {
    exact: true,
    path: "/help",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/PasswordAndSecurity/HelpAndComplaint")
    ),
  },
  {
    exact: true,
    path: "/terms-and-conditions",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/static/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/about",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/static/About")),
  },
  {
    exact: true,
    path: "/privacy",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/static/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/faqs",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/static/Faq")),
  },
  ////Appeal
  {
    exact: true,
    path: "/appeal-process",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/p2p/p2pExchange/Order/Appeal/AppealProcess"
      )
    ),
  },
  {
    exact: true,
    path: "/kyc-wait",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Kyc/Thankyou")),
  },
  {
    exact: true,
    path: "/kyc-done",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Kyc/Congratulation")),
  },
  {
    exact: true,
    guard: true,
    path: "/kyc-fail",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Kyc/KycFailed")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
