export const baseURL = "https://java-ewallet.mobiloitte.io/"; // staging
export const socketURL = "wss://socket-ewallet.mobiloitte.io";
export const chatSocketURL = "wss://notification-ewallet.mobiloitte.io";
let account = `${baseURL}account`;
let auth = `${baseURL}auth`;
let staticManagement = `${baseURL}static`;
let wallet_coin = `${baseURL}wallet/coin`;
let wallet = `${baseURL}wallet/wallet`;
let wallet1 = `${baseURL}wallet`;
let p2pExchange = `${baseURL}p2p-exchange`;
let notification = `${baseURL}notification`;

const apiConfigs = {
  signup: `${account}/signup`,
  verifyOtp: `${account}/verify-otp`,
  verifyUser: `${account}/verify-user`,
  resendOTPEmail: `${account}/forget-password-for-email`,
  verifyMobile: `${account}/verify-phone-otp`,
  resendMobileOTP: `${account}/send-phone-otp`,
  profile: `${account}/my-account`,
  profileUpdate: `${account}/profile-update`,
  saveKycDetails: `${account}/save-kyc-details`,
  uploadFileBase64: `${account}/upload-file-base64`,
  checkDocument: `${account}/check-document`,
  saveKYCdata: `${account}/save-kyc-details`,
  resetPassword: `${account}/reset-password`,
  changePassword: `${account}/change-password`,
  resetPasswordNumber: `${account}/send-phone-otp`,
  enable2FA: `${account}/google-auth-enable`,
  confirmEnable2FA: `${account}/verify-google-code`,
  verifySMSEmailOTP: `${account}/verify-auth-otp`,
  disabledGoogle2FA: `${account}/two-fa-disable`,
  deactivateAccount: `${account}/deactivate-Account`,
  checkUDID: `${account}/check-udid`,
  verify2FAOTP: `${auth}/verify-google`,
  sendEmailAuthOTP: `${auth}/send-2fa-otp`,
  verify2FAEM: `${auth}/verify-auth-otp`,
  login: `${baseURL}auth`,

  //--WALLET MANAGEMENT --//
  coinList: `${wallet_coin}/get-coin-list`,
  livePrice: `${wallet_coin}/live-price`,
  generateAddress: `${wallet}/get-address`,
  getDeposits: `${wallet}/get-deposits`,
  withdraw: `${wallet}/withdraw`,
  approveWithdraw: `${wallet}/approve-withdraw`,
  cancelWithdraw: `${wallet}/cancel-withdraw`,
  transfer: `${wallet}/transfer`,

  getUserWallets: `${wallet}/get-all-user-balance-and-coinlist`,
  getTransactionHistory: `${wallet1}/get-user-transaction-history`,
  userDashboard: `${wallet1}/user-dashboard`,
  addBankAccount: `${wallet1}/add-user-bank-account`,
  userBankList: `${wallet1}/get-user-account-list`,
  deleteBankAccount: `${wallet1}/delete-user-bank-account`,
  checkBankAccount: `${wallet1}/check-accountno`,
  fiatDepositWithdraw: `${wallet1}/fiat-request`,
  getAdminBankAccount: `${wallet1}/get-admin-accounts`,

  //--SWAP--//
  swapCall: `${wallet1}/fiat-to-crypto`,

  //--STATIC MANAGEMENT --//
  staticContent: `${staticManagement}/get-static-content`,
  addHelpAndComplaint: `${staticManagement}/submit-support-ticket`,
  listCategory: `${staticManagement}/get-category`,
  listQuestionsAnswers: `${staticManagement}/get-questions/answers`,

  //--P2P Exchange-----//
  postAdvertisement: `${p2pExchange}/post-advertisement`,
  advertisementList: `${p2pExchange}/advertisement-list`,
  advData: `${p2pExchange}/get-advertisement-data`,
  userInfo: `${p2pExchange}/user-info`,
  sendTradeRequest: `${p2pExchange}/send-trade-request`,
  getAdvTradeData: `${p2pExchange}/get-advertisement/trade-data`,
  report: `${p2pExchange}/report`,
  checkTradeStatus: `${p2pExchange}/trade-status-check`,
  cancelTrade: `${p2pExchange}/send-message-after-cancel-trade-button`,
  notifyPaid: `${p2pExchange}/send-message-after-paid-button`,
  releaseCoin: `${p2pExchange}/release-coin`,
  transactionHistory: `${p2pExchange}/search-and-filters-trade-list`,
  cancelDispute: `${p2pExchange}/cancel-dispute`,

  //--FEEDBACK--//
  sendFeedback: `${account}/submit-user-feedback`,
  getUserFeedbackList: `${account}/get-user-feedback`,
  raiseAppeal: `${p2pExchange}/after-press-dispute-button`,
  tradeFeedbackList: `${account}/get-feedback-list`,
  deleteFeedback: `${account}/delete-feedback`,

  //--CHAT HISTORY----//
  chatHistory: `${notification}/get-chat-data-for-admin`,
  clearNotifications: `${notification}/delete-notification`,
  getNotifications: `${notification}/get-notification-data`,
};
export default apiConfigs;
